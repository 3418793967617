<!--
 * @Author: your name
 * @Date: 2022-04-07 11:27:13
 * @LastEditTime: 2022-04-19 16:07:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\feedback\index.vue
-->
<template>
  <div>
    <div class="feedtop">吐槽一下</div>
    <div class="optionbox">
        <van-radio-group v-model="radio">
          <van-cell-group v-for="(item,index) in list" :key="index">
            <van-cell :title="item.tag" clickable @click="changeRadio(item.id)">
              <template #right-icon>
                <van-radio :name="item.id" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
    </div>
    <div class="title">详细描述</div>
    <van-field v-model="message"  maxlength="200" autosize type="textarea" placeholder="说说看那些是否是反人类的设计"  show-word-limit/>
    <div class="title">请留下你的联系方式，方便我们进一步了解您的需求</div>
    <div class="phone">
      <van-cell-group :class="{android:!showBtn}">
       <van-field v-model="value" placeholder="请输入联系方式"  maxlength="11" type="number" />
      </van-cell-group>
    </div>
    <bluebutton :istitle="istitle" :isloading="isloading" @clkbutton="clkbutton" v-if="showBtn"></bluebutton>
  </div>
</template>
<script>
import bluebutton from "@/components/bluebutton/index.vue"
import isPhone from "@/untils/isPhone.js"
export default {
  data() {
    return {
      radio:"1", // 选中的意见
      message:"",  // 反馈意见
      value:"",  // 用户输入的手机号
      list:"", // 原因描述
      istitle:"提交中",  //提交的默认加载文字
      isloading:true, // 是否加载中
      originalHeight: 0, //原始高度
      screenHeight: 0, //实时高度
      showBtn:true,
      ishone:false,  //是不是苹果机器 
    }
  },
  components:{
    bluebutton
  },
  mounted(){
    // JS原生设置当前背景颜色
    document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5')
    this.getList()
    this.value =sessionStorage.getItem("phone")
    this.originalHeight = document.documentElement.clientHeight || document.body.clientHeight;;
    window.addEventListener('resize', this.watchResize)
    this.ishone = isPhone()
  },
  
  watch:{
    screenHeight(newHeight) { //监听屏幕高度变化
      if(!this.ishone){
        this.showBtn = this.originalHeight <= newHeight;
        this.isandroid = false
      }
    }
  },
  beforeDestroy () {
     window.removeEventListener("resize", this.watchResize)
  },
  methods:{  
    watchResize() {
      //实时变化的窗口高度
      this.screenHeight = document.documentElement.clientHeight;
    },
    // 提交按钮被点击
    clkbutton(){  
      this.isloading = false
      if(this.value == "" || this.message == ""){
        this.$toast(`请您填写手机号或反馈描述`)
        this.isloading = true
        return false
      }
      let data ={
        iccid:sessionStorage.getItem("iccid"),
        phone:this.value,
        desc:this.message,
        tag:this.radio
      }
      this.subFeedBack({data}).then(res =>{
        if(res.data.code == 1)
        {
          this.$toast.success(`提交成功`)
          this.isloading = true
        }else{
          this.$toast("网络错误,请重试")
          this.isloading = true
        }
      })
    },
    // 改变选中的值
    changeRadio(id){
      this.radio =id
    },
    // 获取原因列表
    getList(){
      this.getFeedTags().then(res =>{
        if(res.data.code == 1){
          this.list = res.data.list
          this.radio = res.data.list[this.list.length-1].id
          this.isloading = true
        }else{
          this.$toast(`${res.data.msg}`)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.feedtop{
  width: 345px;
  margin: 0 auto;
  height: 36px;
  line-height: 36px;
  color: #999999;
  font-size: 14px;
}
.optionbox{
 min-width: 100%;
 background-color: #fff;
 border-radius: 10px;
 box-sizing: border-box;
 padding: 6px 0;
 padding-bottom: 20px;
 /deep/.van-cell-group{
   width: 340px;
   height: 54px;
   margin: 0 auto;
   .van-cell{
    width: 340px;
    height: 54px;
    justify-items: center;
    align-items: center;
   }
 }
 /deep/.van-cell-group::after{
     border: none;
     border-bottom: solid 1px #ebedf0;
   }
}
.title{
  width: 345px;
  margin: 0 auto;
  height: 34px;
  line-height: 34px;
  color: #999999;
  font-size: 14px;
}
/deep/.van-cell{
  border-radius: 10px;
}
.phone{
  /deep/.van-cell-group{
    border-radius: 6px;
  }
}
.android{
  margin-bottom: 20px;
}
/deep/.button{
  position: absolute;
  bottom: 20px;
}
</style>